<template>
  <v-app>
    <v-main class="login_background">
      <card-login title="Recuperar senha" card_color="white">
        <template v-slot:form>
          <form-recover :formData.sync="user" ref="form"></form-recover>
        </template>
        <template v-slot:control>
          <v-row>
            <v-col>
              <v-btn text color="primary" to="/login">Voltar</v-btn>
            </v-col>
            <v-col>
              <v-btn
                :loading="loading"
                block
                color="primary"
                @click="clickSubmit"
                >Solicitar</v-btn
              >
            </v-col>
          </v-row>
        </template>
      </card-login>
    </v-main>
  </v-app>
</template>

<script>
import http from "axios";
export default {
  name: "Recover",
  components: {
    CardLogin: () => import("@/_auth/components/containers/_auth_card_01.vue"),
    FormRecover: () => import("@/_auth/components/forms/Mailme.vue")
  },
  data() {
    return {
      user: {
        email: null
      },
      loading: false,
      timeout: 1000
    };
  },
  methods: {
    clickSubmit() {
      this.loading = true;

      setTimeout(() => {
        if (this.$refs.form.validateForm()) {
          this.submitRecover().then(() => {
            this.loading = false;
          });
        } else {
          this.loading = false;
        }
      }, this.timeout);
    },
    submitRecover() {
      return http
        .post("api/auth/password", {
          email: this.user.email,
          redirect_url: origin + "/password" // COLOCAR VARIAVEL GLOBAL ENV?
        })
        .then(data => {
          this.$sweetAlerts.toast1(
            "success",
            "Enviamos um e-mail de confirmação..."
          );
          this.$router.replace("/login");
        })
        .catch(error => {
          this.$sweetAlerts.toast1(
            "error",
            "Não foi possível enviar o email.."
          );
        });
    }
  }
};
</script>

<style>
.login_background {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  background: url('../../assets/background_login.png');
}
</style>
